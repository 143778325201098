let photoArr = [
  {
    docName: '陈亚楠',
    superiority:
      '擅长产科疾病综合诊治疗，对妊娠并发症、妊娠合并症、妊娠特有疾病的诊断和治疗。擅长孕期管理，各种难产处理，产科危、急、重病人的抢救。',
    introduce:
      '河北省急救医学会第一届危重症孕产妇救治专业委员会青年委员；河北省首届妇幼保健协会围产营养与代谢专业委员会委员；河北省妇幼保健协会产后母婴康复专业委员会常务委员；廊坊市医学会围产分会副主委；廊坊市医学会妇产科学分会常委。廊坊市“三三三”人才第三层次人才。从事妇产科临床医疗、科研、教学工作16年，对妇产科常见病、多发病的诊断和治疗有丰富的临床经验。',
    havePhoto: true,
  },
  {
    docName: '崔丽敏',
    superiority:
      '从事妇产科及优生遗传专业20年，擅长产前诊断，孕产期保健，优生优育，遗传咨询等。',
    introduce:
      '河北省预防医学会遗传病防治专业委员会第一届委员，河北省产前诊断与生殖健康专业委员会委员，河北省医师协会临床遗传专业医师分会委员，廊坊市医学会第二届围产学分会常务委员，廊坊市医学会妇产科分会第三届委员会委员。',
    havePhoto: true,
  },
  {
    docName: '付长凤',
    superiority:
      '擅长阴道镜诊断、HPV感染、宫颈病变的诊治、围绝经期综合症、异常子宫出血的诊治。',
    introduce:
      '曾作为主研人的科研课题《微波联合保妇康栓治疗HPV感染CIN的临床研究》获得河北省中医药学会技术三等奖，发表论文十余篇。现任中国医药教育协会妇科专业委员会宫颈病变分会委员；河北省妇幼保健协会首届妇女保健专业委员会常务委员；河北省优生优育协会妇女盆底功能障碍防治专业委员会常务理事；廊坊市医学会医疗事故技术鉴定专家库成员；廊坊市抗癌协会第一届常务理事会理事；廊坊市肿瘤防治联合会理事等。',
    havePhoto: true,
  },
  {
    docName: '高凤红',
    superiority:
      '从事临床工作30余年，具有丰富的妇产科临床、产前筛查、产前诊断及优生优育遗传咨询经验。',
    introduce:
      '现任廊坊市医学会围产医学分会第一届委员。河北省医学会医学遗传分会第四届委员会委员。河北省优生优育协会出生缺陷防治专业委员会委员。从事临床工作30余年，具有丰富的妇产科临床、产前筛查、产前诊断及优生优育遗传咨询经验。',
    havePhoto: true,
  },
  {
    docName: '韩秀英',
    superiority:
      '廊坊市妇幼保健院内科主任。擅长于内科常见病、多发病的诊断及治疗、危重症的抢救以及围产期合并内科疾病的诊断与治疗',
    introduce:
      '廊坊市妇幼保健院内科主任。从事内科临床工作二十年余，多次在京、津、冀三甲医院进修学习。发表国家级核心期刊论文10余篇。2015年以第一主研人完成廊坊市科学技术研究与发展计划项目，并获得2015年度河北省中医药学会科学技术奖二等奖。之前数次主持、参与完成廊坊市科研项目，并获得2011年度廊坊市科学技术奖三等奖、2013年度中医药学会科学技术奖三等奖等。',
    havePhoto: true,
  },
  {
    docName: '何建花',
    superiority:
      '从事妇产科工作近20年。擅长妇科常见病多发病的诊疗、手术，以及妇科内分泌疾病、围绝经期妇女保健及不孕不育相关疾病的诊疗处理。',
    introduce: '从事妇产科工作近20年',
    havePhoto: true,
  },
  {
    docName: '姜岩',
    superiority:
      '擅长妇科肿瘤、子宫内膜异位症、女性不孕不育症、各种妇科内分泌疾病及围绝经期疾病、女性盆底功能障碍及器官脱垂,妇科腔镜手术及阴式手术。各种产科合并症如妊娠合并肝病、糖尿病、心脏病的诊治,擅长治疗妇产科疑难病症。',
    introduce:
      '原中国石油天然气集团公司管道局总医院妇产科主任。河北省妇产科分会常务委员（第十届），河北省医师协会第一届肿瘤分会委员。廊坊市妇产科分会主任委员，《中国乡村医药》杂志社专家委员会成员。主研《中药电热熨治疗慢性盆腔炎研究》课题，获廊坊市科技进步三等奖，参研《放射治疗对宫颈癌病人DNA损伤及hprt基因突变的临床研究》课题，获河北省科学技术一等奖。发表学术论文30余篇。',
    havePhoto: true,
  },
  {
    docName: '焦淑英',
    superiority:
      '擅长孕前、孕期及产后康复、围绝经期保健，尤其孕期营养指导、妊娠期糖尿病的营养治疗，孕期体重管理、妊娠期高血压疾病、胎儿偏大、胎儿偏小的营养治疗。妇女月经病的诊治。',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '李俊华',
    superiority:
      '妇产科各类常见病、多发病,孕前咨询,孕期保健,高危孕产妇管理,巨大儿、胎儿生长受限、孕期体重增长过多及妊娠期糖尿病的营养治疗。',
    introduce:
      '廊坊医学会医疗事故技术鉴定专家库成员，河北省妇幼保健协会围产营养与代谢专业委员。以第一主研人身份完成廊坊市科学技术研究与发展计划项目并获得廊坊市科学技术进步奖二等奖，先后在多家核心期刊发表多篇论文。从事妇产科临床工作16年，2015年于北京市妇产医院综合产科进修。',
    havePhoto: true,
  },
  {
    docName: '王瑞霞',
    superiority:
      '擅长妇科常见病多发病的诊治，不孕不育诊治，围绝经期妇女保健及疾病的诊治，月经异常及子宫异常出血的诊治，多囊卵巢综合征及卵巢功能低下的诊治，妇科肿瘤的诊治，各类计划生育手术，妇科手术等。',
    introduce:
      '廊坊市妇产医院妇科主任。现为河北省首届妇女保健专业委员会委员；河北省生殖健康学会第一届理事；廊坊市抗癌协会常务理事会理事；廊坊市医学会妇产科分会第二届委员会委员。',
    havePhoto: true,
  },
  {
    docName: '王跃侠',
    superiority:
      '擅长妇科常见病多发病的诊疗、手术，以及妇科内分泌疾病、围绝经期妇女保健及不孕不育相关疾病的诊疗处理。',
    introduce:
      '毕业于河北医科大学临床医学系。2011年，曾在北京妇产医院进修妇科。市级科研一项，获得科技进步三等奖。2018年在华西第二医院计划生殖内分泌科进修学习。从事妇产科工作20多年。',
    havePhoto: true,
  },
  {
    docName: '张雅娟',
    superiority:
      '擅长乳腺癌早期诊断，乳腺良性疾病的治疗，如乳腺炎、乳腺增生、乳腺导管扩张、乳腺结节的诊治，乳腺良性肿物的微创手术和手术治疗',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '赵素娥',
    superiority:
      '擅长妇产科各类常见病、多发病、疑难危重病、产前保健以及难产、高危妊娠、妊娠合并症等诊治。',
    introduce:
      '河北省妇幼保健协会促进自然分娩专业委员会委员，廊坊市医学会围产医学分会副主任委员。擅长领域：临床一线工作30余年。先后多次被评为医德医风先进个人，医德医风标兵；2016年荣获“廊坊名医”称号；2017年获得廊坊市委宣传部、市卫计委授予的“最美医生”荣誉称号。',
    havePhoto: true,
  },
  {
    docName: '张璘',
    superiority: '',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '谭燚飞',
    superiority:
      '擅长急慢性损伤引起的颈肩腰膝腿及手足踝关节疼痛的诊治，如颈椎病及相关头晕头痛、上肢麻木疼痛、腰椎间盘突出、腰椎小关节紊乱、膝骨关节炎、踝关节扭伤、踝关节失稳、手足小关节损伤等，同时对通过正骨调整现代职业身形病变如富贵包、驼背以及产后骨盆倾斜、耻骨联合分离等形体结构紊乱颇有心得',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '王晨生',
    superiority:
      '从事超声专业三十余年。处理超声科的急诊、疑难危重病人的超声检查，开展介入超声，独立完成乳腺、甲状腺、劲部淋巴结、肝脏等穿刺诊断。',
    introduce:
      '83年毕业于石河子医学院，获学士学位，2003年晋升主任医师，从事超声专业三十余年。现在河北中石油中心医院，长期担任超声科主任、并任诊断治疗科主任。前后多次到大医院学习进修，2007年赴美国Thomas Jefferson University 学习超声。2002年至今担任廊坊市医学会超声分会主任委员，2001年担任全国超声医学会委员，2000年担任河北省中华医学会超声分会委员，河北省中国超声医学工程学会超声分会委员、2003年担任中国超声医学工程学会肌骨分会超声学会委员。2004年中国医师协会超声分会委员。独立完成廊坊市科研项目一项，参与多项。在各级国内期刊发表论文多篇。出版专著一部。',
    havePhoto: false,
  },
  {
    docName: '秦雨春',
    superiority:
      '在首都儿研所内科工作，主攻新生儿、二内专业，熟悉临床工作，对儿内科专业的医疗，教学，科研工作都有非常丰富的经验。',
    introduce: '',
    havePhoto: false,
  },
  {
    docName: '李静洁',
    superiority:
      '擅长小儿反复呼吸道感染、慢性咳嗽、支气管炎、支气管肺炎、咳嗽变异性哮喘、鼻炎、小儿厌食、脾胃不和，腹泻及小儿抽动症的中西医结合治疗。',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '赵盛华',
    superiority:
      '从事临床工作20余年。擅长健康管理与咨询，慢性病的中医调护。对肺癌、结肠癌、乳腺癌、甲状腺癌、胃癌、肝癌的中医治疗与调护及过敏性哮喘，慢性支气管炎的中医诊疗颇有心得',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '郭凤珍',
    superiority:
      '从事妇女乳腺保健工作30余年，擅长女性青春期、孕前、孕期及哺乳期的乳房保健，乳腺增生症、乳头溢液、乳腺炎、乳腺结节、乳腺纤维瘤、乳腺囊肿等常见病的诊治，长期从事乳腺癌的筛查工作，擅长乳腺癌的早期诊断。',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '李荣萍',
    superiority:
      '主任医师，现在北京市海淀区妇幼保健院医学心理科工作，承担专家技术指导。多年来一直致力于儿科临床医疗和儿童保健相结合，促进儿童早期发展的工作。擅长儿童常见疾病诊治、儿童早期发展评估及指导、儿童生长发育评价及各种测试量表评估检测、儿童生长发育缺陷及防治、儿童早期教育、儿童心理行为发育测查、常见儿童心理行为问题及心理偏异障碍疾病儿童、青少年心理疾病的诊断及康复干预治疗、儿童营养及喂养、营养性缺乏性疾病防治、儿童伤害防治及婴幼儿养育照护、孕产期心理筛查、评估及促进等诸多方面的工作，并有较广泛的深入研究和实践经验。',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '林红梅',
    superiority:
      '擅长妇科常见病，多发病及妇科内分泌相关疾病诊治，如多囊卵巢综合征、异常子宫出血、闭经、不孕症、绝经相关疾病等。',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '武秀丽',
    superiority:
      '擅长儿童常见疾病诊治、儿童早期发展评估及指导、儿童生长发育评价及各种测试量表评估检测、儿童生长发育缺陷及防治、儿童早期教育、儿童心理行为发育测查、常见儿童心理行为问题及心理偏异障碍疾病儿童、青少年心理疾病的诊断及康复干预治疗、儿童营养及喂养、营养性缺乏性疾病防治、儿童伤害防治及婴幼儿养育照护、孕产期心理筛查、评估及促进等诸多方面的工作，并有较广泛的深入研究和实践经验。',
    introduce: '',
    havePhoto: true,
  },
  {
    docName: '杨金智',
    superiority:
      '擅长内科常见病、多发病的诊断及治疗、危重症的抢救以及围产期合并内科疾病的诊断与治疗。',
    introduce: '内科 副主任医师',
    havePhoto: true,
  },
  {
    docName: '商文金',
    superiority:
      '擅长妇科良恶性疾病及疑难病症的微创治疗，包括多孔腹腔镜、单孔腹腔镜、宫腔镜、经阴道手术、抗尿失禁手术。对异常子宫出血、子宫肌瘤、卵巢肿瘤、子宫内膜异位症及腺肌病、不孕不育、生殖道畸形、宫腔粘连、子宫脱垂、压力性尿失禁、妇科恶性肿瘤的诊治有丰富的临床经验。',
    introduce: '主任医师',
    havePhoto: true,
  },
  {
    docName: '郝素影',
    superiority:
      '擅长产科相关疾病的综合诊治疗，对妊娠期糖尿病、妊娠期高血压疾病等妊娠期合并症的诊断和治疗。擅长围产期管理及各种难产处理，产科危、急、重病人的抢救。',
    introduce: '副主任医师',
    havePhoto: true,
  },
]

const deptArr = {
  乳腺科门诊:
    '乳腺科是集保健与医疗相结合，提供个性化保健与治疗的专科科室，诊疗范围主要包括哺乳期乳腺炎，乳腺增生、乳头溢液、乳腺结节，乳腺肿瘤、浆细胞性乳腺炎及乳腺癌筛查诊治及术后康复治疗。特色项目：母乳喂养门诊、乳腺肿物微创手术治疗、闪针多功能乳腺治疗仪治疗。',
  新生儿疾病筛查科:
    '新生儿疾病筛查科承担着全市新生儿遗传代谢病筛查、诊治和随访工作。诊疗范围:儿童遗传性疾病筛查的管理、儿童遗传代谢病的筛查诊疗及遗传咨询;尤其擅长先天性甲状腺功能减低症、苯丙酮尿症、甲基丙二酸血症、肉碱缺乏症等筛查病种的分型诊断及规范性治疗。',
  麻醉门诊:
    '麻醉科是临床一级科室，手术安全的保障学科，舒适医疗的主导学科。麻醉科负责承担妇科、产科日间手术麻醉、急诊手术、危重病人抢救、门诊无痛手术麻醉、全麻下口腔舒适化诊疗、产妇分娩镇痛等工作。',
  生长发育门诊:
    '生长发育科是廊坊市妇幼保健院的特色科室。诊疗范围：身高体重定期监测、骨龄评价、特纳综合征的诊治、营养不良诊治、肥胖儿童指导，尤其擅长矮小症、性早熟及儿童的早期发展、带养困难等指导。',
  身高管理门诊:
    '生长发育科是廊坊市妇幼保健院的特色科室。诊疗范围：身高体重定期监测、骨龄评价、特纳综合征的诊治、营养不良诊治、肥胖儿童指导，尤其擅长矮小症、性早熟及儿童的早期发展、带养困难等指导。',
  中医科:
    '廊坊市妇幼保健院中医科是为弘扬祖国医学、发挥中医药在疾病诊疗中的优势而精心打造的特色科室。科室内集中设置了中医内科、中医妇科、中医儿科、中医康复理疗室、中药房、煎药室等,配备了中药饮片400多种。中医科先后开设了针刺、艾灸、热敏灸、拔罐、刮痧、耳穴、刺血疗法、泥灸、能量舱中药熏蒸、穴位贴敷、小儿推拿等10多种中医特色疗法。自主研发了各类养生特色产品如：养生茶、泡脚包、产后调理包、熏蒸包、消食丸、祛湿健脾丸、驱蚊包、湿疹药浴包、三伏贴、三九贴等，是一个相对独立的中医药综合服务区。',
  宫颈疾病门诊:
    '宫颈疾病门诊是我院特色诊疗科室之一，主要从事外阴、阴道、宫颈疾病的预防、筛查、诊断和治疗工作。目前科室主要开展的检查项目有：薄层液基细胞学(TCT)检测、阴道镜检查及活检、HPV-DNA检测等。治疗项目有：射频治疗外生殖道尖锐湿疣和宫颈、外阴、阴道的低级别病变，不影响生育;LEEP和锐扶刀可以治疗宫颈癌前病变及宫颈较大息肉等。',
  更年期保健门诊:
    '更年期保健门诊为更年期女性提供规范化、精准化、个体化的健康服务，更年期保健咨询，更年期妇女健康体检、妇女内分泌检查，更年期综合征，更年期异常子宫出血，生殖泌尿道萎缩性疾病，绝经后骨质疏松等预防保健、筛查、诊治，更年期盆底康复治疗，建立更年期妇女个人健康档案，提供长期健康保健及疾病的诊治、随诊。',
  妇保科门诊:
    '妇女保健科是廊坊市妇幼保健中心的重点发展科室之一，开展女性两癌筛查、盆底肌功能障碍筛查、乳腺保健门诊、更年期保健等服务，发挥妇幼保健院专业保健的优势。',
  医学遗传与产前诊断科:
    '优生优育，遗传咨询，产前筛查，产前诊断。超声检查提示异常以及孕期致畸因素接触史孕妇的咨询；不良孕产史，出生缺陷儿家庭遗传学诊断及再生育风险评估；优生检查，包括染色体检查，基因检测，SMA基因筛查，单基因携带者筛查，叶酸代谢检查等。',
  儿科门诊:
    '普儿科设有门诊部和住院部。其中门诊分为由北京专家门诊（首都儿科研究所专家）、本院专家门诊（副主任中医师）、中医外治专家门诊（本院主任中医师）、普通门诊、发热门诊。 三楼设门诊治疗区域，主要有门诊输液、雾化吸入、机械排痰、肌肉注射等。住院部开放30张普通床位，13张感染床位。科室独立开展如下检测业务：肺功能检查、鼻腔冲洗、机械辅助排痰、超声波辅助治疗等。',
  妇科:
    '廊坊市妇幼保健院妇科是我院的临床重点科室之一，现有妇科门诊及妇科病区。擅长妇科炎性疾病，宫颈疾病，妇科内分泌疾病，女性不孕，妇科肿瘤等常见病、多发病的诊治。',
  内科:
    '目前科室业务范围除内科常见病多发病的诊断治疗，同时担负着我中心健康体检工作。同时，于2019年1月26日起正式开通职工医保门诊慢性病、新农合慢性病门诊直报、自助结算功能惠及了广大市民，特别是慢病医保新农合慢性病患者，解决了长期在我市其他医院就诊、开药等待时间较长的现象。',
  新生儿门诊:
    '特色项目：廊坊市妇幼保健院新生儿科已具备无创和有创多种模式的呼吸机支持、循环支持、肠外营养支持、PICC 置管术、危重症转运、新生儿复苏、新生儿蓝光照射、新生儿外周动静脉换血术、早产儿视网膜病筛查技术等诊疗技术能力，为患者提供最及时的医疗救治和最优质的医疗服务。',
  儿童康复门诊:
    '廊坊市妇幼保健院是廊坊市听力、视力、智力、孤独症、肢体（脑瘫）残疾儿童康复训练救助定点康复医院，着重为患儿提供全面和系统的康复诊疗服务。我们拥有专业的治疗团队，康复医师6人，康复治疗师12人。并配备先进的康复治疗设备和评估系统，以及吞咽神经肌肉电刺激仪、经颅磁脑电反射电疗仪、低频治疗仪等理疗设备。科室主要开设的治疗项目有：运动疗法（PT）、作业疗法（OT）、言语训练（ST）、感觉统合治疗、认知训练、亲子课、集体课、针灸推拿、理疗等。可治疗小儿脑瘫、脑外伤后遗症、脑炎后遗症运动发育迟缓、语言发育迟缓、智力发育落后、感觉统合异常、自闭症、斜颈、扁平足、脊柱侧弯等疾病。',
  儿保科门诊:
    '科室致力于为广大儿童及家长提供有关儿童生长发育、心理行为问题、健康教育以及儿童康复等方面的专业指导、咨询及诊治。已开展的服务项目有儿童群体保健：为0-6岁散居儿童进行健康体检；负责托幼机构入园儿童、在园儿童以及保教人员的健康体检；儿童心理行为：对儿童进行心理行为发育评估，对早期发现心理行为发育偏离的儿童提供心理咨询、干预、转诊和随访服务；儿童营养与喂养：对蛋白质-营养不良，营养性缺铁性贫血，维生素D缺乏性佝偻病，营养元素缺乏等营养性疾病的诊疗和个性化膳食指导；儿童眼保健：对0~6岁儿童进行眼外观、屈光检查，对4 岁及以上儿童增加视力检查、视力评估、眼位等，保护和促进儿童视功能的正常发育；儿童康复：针对发育落后、发育异常及急性损伤后遗症、慢性疾病恢复期等儿童，开展神经运动功能障碍的筛查、评估和诊断；高危儿管理：对早产儿、低出生体重儿、体重〈X-2SD〉、活动期佝偻病、中重度贫血、中重度营养不良、反复呼吸道及消化道感染、单纯性肥胖、先心病、智力发育迟缓、心理行为发育异常高危儿童进行个案管理，并定期进行随访； 先心病筛查：对所有市区户籍出生的2~3个月婴儿进行免费先心病筛查。初筛异常的婴幼儿由河北省儿童医院专家进行免费复筛和诊断； 营养元素测定：有针对性地补充，更好地做好营养及保健工作；骨密度检测：骨密度检测适用于各类人群，能更好的检测骨骼状况；新生儿行为神经测定（NBNA）：能够早期发现脑损伤引起的新生儿行为神经异常，以便早期干预,防治伤残。',
  儿童营养与喂养门诊:
    '主要服务内容包括生长发育监测：对个体儿童的体重、身高进行定期监测，便于早期发现儿童生长偏离的现象，如消瘦、矮小、肥胖、营养不良等，采取相应的措施进行早期干预；营养与喂养指导:营养与喂养个性化指导，进行婴幼儿辅食添加指导，合理膳食指导，食物过敏的膳食指导，饮食行为等科学喂养知识指导;常见儿童营养性疾病防治:蛋白质-营养不良，营养性缺铁性贫血，维生素D缺乏性佝偻病，营养元素缺乏等营养性疾病的诊疗和个性化膳食指导。',
  生殖健康与不孕不育门诊:
    '诊治与生育相关女性疾病，调节女性激素水平，治疗因女性内分泌导致的生育问题，咨询优生优育相关问题，提供生育指导生育规划，监测女性卵泡生长，治疗排卵问题，诊治输卵管子宫疾病导致的生育障碍。',
}

export { photoArr, deptArr }
